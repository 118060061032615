import type { Currency } from "~/types/currencies";
import {
  getCurrentCurrency,
  BASE_CURRENCY,
} from "~/utilities/constants/CURRENCIES";
import {
  Api,
  type UpdateUserDTO,
  type EstateResponseDTO,
  type LandResponseDTO,
  type CreateReviewDTO,
  type UserDTO,
} from "~/services/swagger/Api";
import { Notification } from "~/services/notifications/toast";
import { jwtDecode } from "jwt-decode";
import { apiPost } from "~/services/api";
import getRoute from "~/utilities/configs/apiMap";
import {
  getLocalStorage,
  writePersistentLocalStorage,
} from "~/services/LocalStorage/localStorage";

interface IPermission {
  paths: string[];
}

type Role = "default" | "admin" | "guest" | "service";

const apiClient = new Api();
const nuxtApp = useNuxtApp();

export const userStore = defineStore("userStore", {
  state: () => ({
    currentCurrency: getCurrentCurrency(BASE_CURRENCY.code) as Currency,
    role: "" as Role,
    id: "",
    avatar: "",
    permissions: {
      paths: [],
    } as IPermission,
    isInSystem: false,
    favoriteEstates: [] as EstateResponseDTO[],
    favoriteLands: [] as LandResponseDTO[],
    currentUser: {} as UserDTO,
  }),
  actions: {
    getPermissions() {
      this.getPermissionPaths();
    },
    getPermissionPaths() {
      if (this.role === "admin") {
        this.permissions.paths = ["admin", ...this.permissions.paths];
      }
    },
    async fetchUserData() {
      try {
        const data = await apiClient.api.usersControllerGetMe();
        if (!data.ok) return;
        this.role = data.data.role;
        this.avatar = data.data.avatarFile?.url ?? "";
        this.getPermissions();
        this.isInSystem = true;
        this.favoriteEstates = data.data.estates;
        this.favoriteLands = data.data.lands;
        this.id = data.data.id;
        this.currentUser = data.data;
        return data.data;
      } catch (error) {
        console.log("error: ", error);
        const errorMessage =
          (error as Error).message || nuxtApp.$i18n.t("errors.catch_all");
        createErrorNotify({ message: errorMessage });
      }
    },
    async updateUserData(updatedUser: UpdateUserDTO) {
      try {
        const data = await apiClient.api.usersControllerUpdate(updatedUser);
        if (!data.ok) return;
        // this.fetchUserData();
        return data;
      } catch (error) {
        createErrorNotify({
          message: nuxtApp.$i18n.t("errors.catch_all"),
          delay: 3000,
        });
        return null;
      }
    },
    async userSignOut() {
      try {
        const data = await apiClient.api.authControllerSignOut();
        if (!data.ok) return;
        const token = useCookie("acc_token");
        token.value = null;
        this.role = "default";
        this.avatar = "";
        this.permissions.paths.length = 0;
        this.isInSystem = false;
        const localePath = useLocalePath();
        const route = localePath("/");
        navigateTo(route);
      } catch (error) {
        const errorMessage =
          (error as Error).message || nuxtApp.$i18n.t("errors.catch_all");
        createErrorNotify({ message: errorMessage, delay: 3000 });
      }
    },
    async changePassword(args: {
      password: string;
      oldPassword: string;
      isEmailSent: boolean;
    }) {
      if (args.isEmailSent) {
        this.changePasswordConfirm({
          password: args.password,
          token: args.oldPassword,
        });
        return;
      }
      try {
        const passwordChangeRes =
          await apiClient.api.authNativeControllerPasswordChange({
            password: args.password,
            oldPassword: args.oldPassword,
          });
        if (!passwordChangeRes.ok) {
          return;
        }
      } catch (error) {
        const errorMessage =
          (error as Error).message || nuxtApp.$i18n.t("errors.catch_all");
        createErrorNotify({ message: errorMessage, delay: 3000 });
      }
    },
    async changePasswordConfirm(args: { password: string; token: string }) {
      try {
        const passwordChangeRes =
          await apiClient.api.authNativeControllerPasswordChangeConfirm({
            password: args.password,
            token: args.token,
          });
        if (!passwordChangeRes.ok) {
          return;
        }
      } catch (error) {
        const errorMessage =
          (error as Error).message || nuxtApp.$i18n.t("errors.catch_all");
        createErrorNotify({ message: errorMessage, delay: 3000 });
      }
    },
    async loginUser(args: { email: string; password: string }) {
      try {
        const loginResponse = await apiClient.api.authNativeControllerSignIn({
          login: args.email,
          password: args.password,
        });
        if (!loginResponse.ok) {
          return;
        }
        const token = useCookie("acc_token");
        const refreshToken = useCookie("acc_refresh_token");
        token.value = loginResponse.data?.accessToken;
        refreshToken.value = loginResponse.data.refreshToken;
        this.isInSystem = true;
        return true;
      } catch (error) {
        createErrorNotify({ message: "Email or password is incorrect" });
        return false;
      }
    },
    async signUpUser(args: {
      email: string;
      password: string;
      userName: string;
      isMailTokenRecived: boolean;
    }) {
      if (args.isMailTokenRecived) {
        const res = await this.checkSignUpEmailToken({
          email: args.email,
          // userName:args.userName,
          token: args.password,
        });
        this.isInSystem = Boolean(res);
        return res;
      }
      try {
        const SignUpResponse = await apiClient.api.authNativeControllerSignUp({
          username: args.userName,
          email: args.email,
          password: args.password,
        });
        if (!SignUpResponse.ok) return false;
        const emailSent = new Notification({
          type: "system",
          message: nuxtApp.$i18n.t("login.log_check_email"),
          timeout: 5000,
        });
        emailSent.createNewToast();
        return true;
      } catch (error) {
        const e = (error as Error).message;
        if (e.includes("user")) return;
        // const errorMessage = e || nuxtApp.$i18n.t("errors.catch_all");
        // createErrorNotify({ message: errorMessage });
      }
    },
    async checkSignUpEmailToken(args: { email: string; token: string }) {
      try {
        const loginResponse =
          await apiClient.api.authNativeControllerSignUpConfirm({
            email: args.email,
            token: args.token,
          });
        if (!loginResponse.ok) {
          return false;
        }
        const token = useCookie("acc_token");
        const refreshToken = useCookie("acc_refresh_token");
        token.value = loginResponse.data?.accessToken;
        refreshToken.value = loginResponse.data?.refreshToken;
        this.isInSystem = true;
        return true;
      } catch (error) {
        const errorMessage =
          (error as Error).message || nuxtApp.$i18n.t("errors.catch_all");
        createErrorNotify({ message: errorMessage });
      }
    },
    async refreshAccToken() {
      const refreshToken = useCookie("acc_refresh_token");
      const token = useCookie("acc_token");
      try {
        const refreshResponse = await apiClient.api.authControllerRefresh({
          headers: {
            Authorization: `Bearer ${refreshToken.value}`,
          },
        });
        if (!refreshResponse.ok) {
          return;
        }
        token.value = refreshResponse.data?.accessToken;
        refreshToken.value = refreshResponse.data?.refreshToken;
        return true;
      } catch (error) {
        const errorMessage =
          (error as Error).message || nuxtApp.$i18n.t("errors.catch_all");
        createErrorNotify({ message: errorMessage });
        return null;
      }
    },
    async checkAccTokenExp(token: string) {
      const decodedToken: any = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp < currentTime) {
        const res = await this.refreshAccToken();
        if (!res) {
          const token = useCookie("acc_token");
          const refreshToken = useCookie("acc_refresh_token");
          token.value = null;
          refreshToken.value = null;
          createErrorNotify({
            message: "You session is expired. Please login again.",
          });
        }
        return res;
      } else {
        return true;
      }
    },
    async getFavoritsEstates(idsArray: string[] | undefined) {
      if (!idsArray || !idsArray.length) return null;
      try {
        const favoritesResponse = await apiClient.api.estatesControllerListPost(
          {
            ids: idsArray,
          }
        );
        if (!favoritesResponse.ok) {
          return null;
        }
        return favoritesResponse.data.rows;
      } catch (error) {
        createErrorNotify({
          message: "Couldn't get your favorites, try later please.",
        });
        return null;
      }
    },
    async refreshEmailSingUp(email: string) {
      try {
        const refreshRes = await apiClient.api.authNativeControllerSignUpResend(
          {
            email: email,
          }
        );
        if (!refreshRes.ok) {
          return null;
        }
      } catch (error) {
        const errorMessage =
          (error as Error).message || nuxtApp.$i18n.t("errors.catch_all");
        createErrorNotify({ message: errorMessage });
      }
    },
    async restoreUserAccount(args: { email: string }) {
      try {
        await apiClient.api.authNativeControllerPasswordRecovery({
          email: args.email,
        });
        return true;
      } catch (error) {
        createErrorNotify({
          message: nuxtApp.$i18n.t("errors.catch_all"),
        });
        return false;
      }
    },
    async restoreUserAccountConfirm(args: {
      email: string;
      token: string;
      password: string;
    }) {
      try {
        await apiClient.api.authNativeControllerPasswordRecoveryConfirm({
          email: args.email,
          token: args.token,
          password: args.password,
        });
        await this.loginUser({ email: args.email, password: args.password });
        const localePath = useLocalePath();
        const route = localePath("/profile");
        navigateTo(route);
        return true;
      } catch (error) {
        createErrorNotify({
          message: "Code from email is not valid.",
        });
        return false;
      }
    },
    async singInWithGoogle(code: string) {
      try {
        const res = await apiClient.api.authGoogleControllerAuth({
          query: { code: code },
        });
        if (!res.ok) return;
        const token = useCookie("acc_token");
        const refreshToken = useCookie("acc_refresh_token");
        token.value = res.data?.accessToken;
        refreshToken.value = res.data?.refreshToken;
        this.isInSystem = true;
      } catch (error) {
        const errorMessage =
          (error as Error).message || nuxtApp.$i18n.t("errors.catch_all");
        createErrorNotify({ message: errorMessage });
      }
    },

    async leaveReview(id: string, review: CreateReviewDTO) {
      try {
        const res = await apiClient.api.reviewsControllerCreate(id, review);
        if (!res.ok) {
          createErrorNotify({
            message: "Some error occured,please try later.",
          });
          return null;
        }
        return res.data;
      } catch (error) {
        createErrorNotify({ message: "Some error occured,please try later." });
        console.log(error);
        return null;
      }
    },
    async checkUserName(name: string) {
      try {
        const response = await apiPost({
          url: getRoute({ endpont: "post_check_username" }),
          body: { username: name },
        });
        return response;
      } catch (error) {
        return null;
      }
    },
    generateUserUID() {
      if (typeof crypto.randomUUID === "function") {
        return crypto.randomUUID();
      }
      return `${Date.now()}-${Math.random().toString(36).substring(2, 10)}`;
    },
    createUserUID() {
      const existingUID = localStorage.getItem("user_uid");
      if (existingUID) {
        return existingUID;
      }
      const newUID = this.generateUserUID();
      localStorage.setItem("user_uid", newUID);
      return newUID;
    },
  },
});

const createErrorNotify = (args: { message: string; delay?: number }) => {
  const errorNotify = new Notification({
    type: "error",
    message: args.message,
    timeout: args.delay ?? 5000,
  });
  errorNotify.createNewToast();
};
